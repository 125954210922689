import React, { useState } from 'react';
import './Navbar.css';
import { logo } from '../../assets/imagesPath';
import { NavLink } from 'react-router-dom';
import PATHS from '../../routes/Paths';

const NavbarWithoutAuth = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light p-4 p-lg-3 ps-lg-5 pe-lg-5 justify-content-between'>
        <img
          src={logo}
          alt=''
          className='img-fluid brand-logo'
        />

        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarTogglerDemo01'
          aria-controls='navbarTogglerDemo01'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div
          className={`collapse navbar-collapse align-items-center justify-content-end ${isDropdownOpen}`}
          id='navbarTogglerDemo01'
        >
          <div className='d-lg-flex d-grid justify-content-center align-items-center gap-4 gap-lg-5 mt-5 mt-lg-0'>

            <ul className='navbar-nav me-auto mb-2 mb-lg-0 gap-4 gap-lg-5 w-100 text-center' onClick={handleDropdownToggle}>
              <li className='nav-item'>
                <NavLink
                  to={PATHS.terms}
                  className='nav-link'
                  data-toggle='tab'
                >
                  Terms of Use
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to={PATHS.privacypolicy}
                  className='nav-link'
                  data-toggle='tab'
                >
                  Privacy Policy
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to={PATHS.faqs}
                  className='nav-link'
                  data-toggle='tab'
                >
                  FAQs
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to={PATHS.signin}
                  className='nav-link'
                  data-toggle='tab'
                >
                  Login
                </NavLink>
              </li>
            </ul>

          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarWithoutAuth;