import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "authSlice",
    version: 1,
    storage,
    blacklist: [],
};

const initialState = {
    isLoggedIn: false,
    userDetail: null,
    shopDetail: null,
    token: null,
    rememberMe: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loggedIn: (state, data) => {
            state.isLoggedIn = true;
            state.userDetail = data.payload.user;
            state.token = data.payload.token;
            state.userId = data.payload.userId;
            state.fcmtoken = data.payload.fcmtoken;
        },

        userLogout: (state) => {
            state.isLoggedIn = false;
            state.userDetail = null;
            state.token = null;
            state.userId = null;
            state.fcmtoken = null;
        },

        rememberMeSlice: (state, data) => {
            state.rememberMe = data.payload;
        },

        shopProfileUpdate: (state, data) => {
            state.shopDetail = data.payload;
        },

        updateUserProfile: (state, data) => {
            state.userDetail = { ...state.userDetail, ...data.payload };
        },
    },
});

export const { loggedIn, userLogout, rememberMeSlice, updateUserProfile, shopProfileUpdate } = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);