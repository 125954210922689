import { SplitApiSettings } from "./SplitApiSetting";
import { API_END_POINTS } from "./ApiEndpoints";

export const api = SplitApiSettings.injectEndpoints({
    reducerPath: "api",
    refetchOnMountOrArgChange: true,

    endpoints: (builder) => ({
        /////////////////////////////<===MUTATIONS===>//////////////////////////////
        register: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.register,
                method: "POST",
                body: { ...data },
            }),
        }),

        loginuser: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.login,
                method: "POST",
                body: { ...data },
            }),
        }),

        forgotPassword: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.forgotPassword,
                method: "POST",
                body: { ...data },
            }),
        }),

        logout: builder.mutation({
            query: (data) => ({
                url: API_END_POINTS.logout,
                method: "POST",
                body: data,
            }),
        }),

        createshop: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.createShop,
                    method: "POST",
                    body: data,
                };
            }
        }),

        profilUpdate: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.profilUpdate,
                    method: "POST",
                    body: data,
                };
            }
        }),

        warrantyClaim: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.warrantyClaim,
                method: "POST",
                body: data,
            }),
        }),

        warrantySale: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.warrantySale,
                method: "POST",
                body: data,
            }),
        }),

        addMechanic: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.addMechanic,
                method: "POST",
                body: data,
            }),
        }),

        editMechanic: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.editMechanic,
                method: "POST",
                body: data,
            }),
        }),

        deleteMechanic: builder.mutation({
            query: (data) => ({
                url: API_END_POINTS.deleteMechanic,
                method: "POST",
                body: data,
            }),
        }),

        shopRating: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.shopRating,
                method: "POST",
                body: { ...data },
            }),
        }),

        truckerRequestCreate: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.truckerRequestCreate,
                method: "POST",
                body: { ...data },
            }),
        }),

        truckerRequestAccept: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.truckerRequestAccept,
                method: "POST",
                body: { ...data },
            }),
        }),

        stripePayment: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.stripePayment,
                method: "POST",
                body: { ...data },
            }),
        }),

        switchChatRequest: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.switchChatRequest,
                method: "POST",
                body: { ...data },
            }),
        }),

        enterVideoCallRequest: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.enterVideoCallRequest,
                method: "POST",
                body: { ...data },
            }),
        }),

        endChatRequest: builder.mutation({
            query: (data) => ({
                url: API_END_POINTS.endChatRequest,
                method: "POST",
                body: data,
            }),
        }),

        mechanicRequest: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.mechanicRequest,
                method: "POST",
                body: { ...data },
            }),
        }),

        onMyWayRequest: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.onMyWayRequest,
                method: "POST",
                body: { ...data },
            }),
        }),

        completeRequest: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.completeRequest,
                method: "POST",
                body: { ...data },
            }),
        }),

        retryRequest: builder.mutation({
            query: ({ data }) => ({
                url: API_END_POINTS.retryRequest,
                method: "POST",
                body: { ...data },
            }),
        }),

        refundPayment: builder.mutation({
            query: () => {
                return {
                    url: API_END_POINTS.refundPayment,
                    method: "POST",
                };
            },
        }),

        chatNotify: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.chatNotify,
                    method: "POST",
                    body: { ...data },
                };
            },
        }),

        addMoreServices: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.addMoreServices,
                    method: "POST",
                    body: { ...data },
                };
            },
        }),

        updateShopProfile: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.updateShopProfile,
                    method: "POST",
                    body: data,
                };
            },
        }),

        deactivateShop: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.deactivateShop,
                    method: "POST",
                    body: data,
                };
            },
        }),

        activateShop: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.activateShop,
                    method: "POST",
                    body: data,
                };
            },
        }),

        shopPriceQuotation: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.shopPriceQuotation,
                    method: "POST",
                    body: data,
                };
            },
        }),

        contactUs: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.contactUs,
                    method: "POST",
                    body: data,
                };
            },
        }),

        requestAcceptByTrucker: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.requestAcceptByTrucker,
                    method: "POST",
                    body: data,
                };
            },
        }),

        ratingFromMechanic: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.ratingFromMechanic,
                    method: "POST",
                    body: data,
                };
            },
        }),

        cancelRequestByTrucker: builder.mutation({
            query: () => {
                return {
                    url: API_END_POINTS.cancelRequestByTrucker,
                    method: "GET",
                };
            },
        }),

        creditWallet: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.creditWallet,
                    method: "POST",
                    body: data,
                };
            },
        }),

        withdrawShopAmount: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.withdrawShopAmount,
                    method: "POST",
                    body: data,
                };
            },
        }),

        stripePaymentIntent: builder.mutation({
            query: ({ data }) => {
                return {
                    url: API_END_POINTS.stripePaymentIntent,
                    method: "POST",
                    body: data,
                };
            },
        }),

        /////////////////////////////<===QUERIES===>////////////////////////////////
        viewTruckerRequest: builder.query({
            query: ({ params }) => {
                return {

                    url: API_END_POINTS.viewTruckerRequest,
                    method: "GET",
                    params,
                };
            },
        }),

        getAllMechanics: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllMechanics,
                    method: "GET",
                };
            },
        }),

        getActiveRequests: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getActiveRequests,
                    method: "GET",
                };
            },
        }),

        getActiveLiveChatRequests: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getActiveLiveChatRequests,
                    method: "GET",
                };
            },
        }),

        getTruckerDetails: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getTruckerDetails,
                    method: "GET",
                };
            },
        }),

        viewShopProfile: builder.query({
            query: ({ params }) => {
                return {
                    url: API_END_POINTS.viewShopProfile,
                    method: "GET",
                    params,
                };
            },
        }),

        shopProfile: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.shopProfile,
                    method: "GET",
                };
            },
        }),

        cmsPages: builder.query({
            query: ({ params }) => {
                return {
                    url: API_END_POINTS.cmsPages,
                    method: "GET",
                    params,
                };
            },
        }),

        faqs: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.faqs,
                    method: "GET",
                };
            },
        }),

        mechanicDirectories: builder.query({
            query: ({ params }) => {
                return {
                    url: API_END_POINTS.mechanicDirectories,
                    method: "GET",
                    params,
                };
            },
        }),

        history: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.history,
                    method: "GET",
                };
            },
        }),

        pendingRequests: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.pendingRequests,
                    method: "GET",
                };
            },
        }),

        walletBalance: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.walletBalance,
                    method: "GET",
                };
            },
        }),

        pendingReqForTrucker: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.pendingReqForTrucker,
                    method: "GET",
                };
            },
        }),

        transactionHistory: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.transactionHistory,
                    method: "GET",
                };
            },
        }),

        shopTotalEarnings: builder.query({
            query: ({ params }) => {
                return {
                    url: API_END_POINTS.shopTotalEarnings + "/" + params,
                    method: "GET",
                };
            },
        }),

    }),

    overrideExisting: true,
});

export const {
    /////////////////////////////<===MUTATIONS===>//////////////////////////////
    useRegisterMutation,
    useLoginuserMutation,
    useForgotPasswordMutation,
    useLogoutMutation,
    useCreateshopMutation,
    useProfilUpdateMutation,
    useWarrantyClaimMutation,
    useWarrantySaleMutation,
    useAddMechanicMutation,
    useEditMechanicMutation,
    useDeleteMechanicMutation,
    useShopRatingMutation,
    useTruckerRequestCreateMutation,
    useTruckerRequestAcceptMutation,
    useStripePaymentMutation,
    useSwitchChatRequestMutation,
    useEnterVideoCallRequestMutation,
    useEndChatRequestMutation,
    useMechanicRequestMutation,
    useOnMyWayRequestMutation,
    useCompleteRequestMutation,
    useRetryRequestMutation,
    useRefundPaymentMutation,
    useChatNotifyMutation,
    useAddMoreServicesMutation,
    useDeactivateShopMutation,
    useActivateShopMutation,
    useUpdateShopProfileMutation,
    useShopPriceQuotationMutation,
    useContactUsMutation,
    useRequestAcceptByTruckerMutation,
    useRatingFromMechanicMutation,
    useCancelRequestByTruckerMutation,
    useCreditWalletMutation,
    useWithdrawShopAmountMutation,
    useStripePaymentIntentMutation,
    /////////////////////////////<===QUERIES===>////////////////////////////////
    useViewTruckerRequestQuery,
    useGetAllMechanicsQuery,
    useGetActiveRequestsQuery,
    useGetActiveLiveChatRequestsQuery,
    useGetTruckerDetailsQuery,
    useViewShopProfileQuery,
    useShopProfileQuery,
    useCmsPagesQuery,
    usePrivacyQuery,
    useFaqsQuery,
    useMechanicDirectoriesQuery,
    useHistoryQuery,
    usePendingRequestsQuery,
    useWalletBalanceQuery,
    usePendingReqForTruckerQuery,
    useTransactionHistoryQuery,
    useShopTotalEarningsQuery,
} = api;
