import React, { useEffect } from 'react';
import './Navbar.css';
import { dummyProfileImg, logo } from '../../assets/imagesPath';
import Button from '../button/Button';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import { useLogoutMutation, usePendingReqForTruckerQuery, useShopProfileQuery } from '../../services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { shopProfileUpdate, userLogout } from '../../redux/AuthSlice';
import { activeRequestsChats, chatTypeDetail, clickedActiveRequest, clickedRequest, currentLocationData, getAllRequest, getTruckerRequest, mechanicDetails, shopAndTruckerData, shopDetailsOnRequest, truckerDetails, truckerRequestAcceptData } from '../../redux/requestSlice';
import { toast } from 'react-toastify';
import { BASE_URL_IMAGES } from '../../services/ApiEndpoints';
import { useState } from 'react';
import { database } from '../firebaseConfig/firebaseConfig';
import LogoutFunction from '../logoutFunction/LogoutFunction';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const auth = useSelector((data) => data.auth);
  const userDetail = auth?.userDetail;
  const shopDetail = auth?.shopDetail;
  const requestSent = useSelector((data) => data?.request?.requestSent);
  const roadsideWaitingState = useSelector((data) => data?.request);
  const dbRef = database.ref(`request`);

  const [logout] = useLogoutMutation();

  const {
    data: pendingReqForTrucker,
    refetch: pendingReqForTruckerRefetch,
    error: pendingReqForTruckerError,
  } = usePendingReqForTruckerQuery();

  const activeTabs = pendingReqForTrucker?.data?.pendingRequests;

  const {
    data: shopProfile,
    refetch,
    error: shopProfileError,
  } = useShopProfileQuery();

  useEffect(() => {
    refetch();
  }, [shopDetail]);

  useEffect(() => {
    pendingReqForTruckerRefetch();
  }, [requestSent]);

  useEffect(() => {
    pendingReqForTruckerRefetch();
  }, []);

  useEffect(() => {
    if ((activeTabs?.request_type_id == 1 || activeTabs?.request_type_id == 2) || requestSent || roadsideWaitingState?.roadsideWaiting) {
      navigator(PATHS.waitForResponse);
    }
  }, [activeTabs, requestSent, roadsideWaitingState?.roadsideWaiting]);

  const logoutFunc = () => {

    const data = {
      token: auth?.fcmtoken,
    };

    logout(data)
      .unwrap()
      .then(() => {
        dispatch(userLogout(null));
        dispatch(getAllRequest(null));
        dispatch(activeRequestsChats(null));
        dispatch(clickedRequest(null));
        dispatch(clickedActiveRequest(null));
        dispatch(shopDetailsOnRequest(null));
        dispatch(mechanicDetails(null));
        dispatch(truckerDetails(null));
        dispatch(chatTypeDetail(null));
        dispatch(getTruckerRequest(null));
        dispatch(currentLocationData(null));
        dispatch(truckerRequestAcceptData(null));
        dispatch(shopAndTruckerData(null));
        dispatch(shopProfileUpdate(null));
        navigator(PATHS.signin);
        toast.success('Logout Successfully');
      })
      .catch((error) => {
        dispatch(userLogout(null));
        navigator(PATHS.signin);
      })
      .finally(() => {
        dispatch(userLogout(null));
        navigator(PATHS.signin);
      });
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    database
      .ref(dbRef)
      .orderByKey()
      .on("value", (snapshot) => {
        snapshot?.forEach((childSnapshot) => {
          const allRequests = childSnapshot.val();
          if (auth?.userDetail?.role_as == "supermechanic") {
            if (allRequests?.shop?.shop?.status == "unapproved" && allRequests?.shop?.shop?.id == auth?.userDetail?.shop?.id) {
              logoutFunc();
              dispatch(userLogout());
              // toast.error("You have been banned by admin");
              navigator(PATHS.signin);
            }
          }

          if (auth?.userDetail?.role_as == "mechanic") {
            if (allRequests?.mechanic?.mechanic?.status == "banned" && allRequests?.mechanic?.mechanic?.id == auth?.userDetail?.id) {
              logoutFunc();
              dispatch(userLogout());
              // toast.error("You have been banned by admin");
              navigator(PATHS.signin);
            }
          }

          if (auth?.userDetail?.role_as == "trucker") {
            if (allRequests?.trucker?.trucker?.status == "banned" && allRequests?.trucker?.trucker?.id == auth?.userDetail?.id) {
              logoutFunc();
              dispatch(userLogout());
              // toast.error("You have been banned by admin");
              navigator(PATHS.signin);
            }
          }
        });
      });
  }, [auth.userDetail]);

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light p-4 p-lg-3 ps-lg-5 pe-lg-5 justify-content-between'>
        {/* <LogoutFunction error={pendingReqForTruckerError} />
        <LogoutFunction error={shopProfileError} /> */}
        {activeTabs ?
          <img
            src={logo}
            alt=''
            className='img-fluid brand-logo'
          />
          :
          <img
            src={logo}
            alt=''
            className='img-fluid brand-logo cursor'
            onClick={() => {
              if (userDetail?.role_as == 'supermechanic') {
                navigator(PATHS.shophome);
              } else if (userDetail?.role_as == 'mechanic') {
                navigator(PATHS.mechanichome);
              } else {
                navigator(PATHS.truckerhome);
              }
            }}
          />
        }

        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarTogglerDemo01'
          aria-controls='navbarTogglerDemo01'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div
          className={`collapse navbar-collapse align-items-center justify-content-end ${isDropdownOpen}`}
          id='navbarTogglerDemo01'
        >
          <div className='d-lg-flex d-grid justify-content-center align-items-center gap-4 gap-lg-5 mt-5 mt-lg-0'>

            {activeTabs ?
              <ul className='navbar-nav me-auto mb-2 mb-lg-0 gap-4 gap-lg-5 w-100 text-center' onClick={handleDropdownToggle}>
                <li className='nav-item'>
                  <span className='nav-link disabled'>Home</span>
                </li>
                <li className='nav-item'>
                  <span className='nav-link disabled'>Terms of Use</span>
                </li>
                <li className='nav-item'>
                  <span className='nav-link disabled'>Privacy Policy</span>
                </li>
                <li className='nav-item'>
                  <span className='nav-link disabled'>FAQs</span>
                </li>
                {auth?.userDetail?.role_as != "mechanic" &&
                  <li className='nav-item'>
                    <span className='nav-link disabled'>Contact us</span>
                  </li>
                }
                {userDetail?.role_as == 'supermechanic' &&
                  <li className='nav-item'>
                    <span className='nav-link disabled'>Mechanics</span>
                  </li>
                }
              </ul>
              :
              <ul className='navbar-nav me-auto mb-2 mb-lg-0 gap-4 gap-lg-5 w-100 text-center' onClick={handleDropdownToggle}>
                <li className='nav-item'>
                  <NavLink
                    to={userDetail?.role_as == 'supermechanic' ? PATHS.shophome : userDetail?.role_as == 'mechanic' ? PATHS.mechanichome : PATHS.truckerhome}
                    className='nav-link'
                    data-toggle='tab'
                  >
                    Home
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink
                    to={PATHS.terms}
                    className='nav-link'
                    data-toggle='tab'
                  >
                    Terms of Use
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink
                    to={PATHS.privacypolicy}
                    className='nav-link'
                    data-toggle='tab'
                  >
                    Privacy Policy
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink
                    to={PATHS.faqs}
                    className='nav-link'
                    data-toggle='tab'
                  >
                    FAQs
                  </NavLink>
                </li>
                {auth?.userDetail?.role_as != "mechanic" &&
                  <li className='nav-item'>
                    <NavLink
                      to={PATHS.contactus}
                      className='nav-link'
                      data-toggle='tab'
                    >
                      Contact us
                    </NavLink>
                  </li>
                }
                {userDetail?.role_as == 'supermechanic' &&
                  <li className='nav-item'>
                    <NavLink
                      to={PATHS.mechanics}
                      className='nav-link'
                      data-toggle='tab'
                    >
                      Mechanics
                    </NavLink>
                  </li>
                }
              </ul>
            }

            <div className='d-flex ms-lg-5'>
              {!auth.isLoggedIn && (
                <Link to={PATHS.signin}>
                  <Button
                    label='Sign in'
                    className='whiteBtnNavbar'
                  />
                </Link>
              )}
            </div>

            {auth.isLoggedIn && (
              <div className='dropdown profileDropdown mx-auto'>
                <a
                  className='d-flex align-items-center decorNone flex-column flex-lg-row gap-1  text-capitalize'
                  href='#'
                  role='button'
                  id='dropdownMenuLink'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  disabled={activeTabs ? true : false}
                >
                  {userDetail?.role_as == 'supermechanic' ?
                    <img
                      src={BASE_URL_IMAGES + shopProfile?.data?.ShopDetails?.image || BASE_URL_IMAGES + shopDetail?.image || dummyProfileImg}
                      onError={(e) => { e.target.onerror = null; e.target.src = dummyProfileImg; }}
                      height={40}
                      width={40}
                      className='me-2 rounded-circle'
                      alt=''
                    />
                    :
                    <img
                      src={BASE_URL_IMAGES + userDetail?.images?.url || dummyProfileImg}
                      onError={(e) => { e.target.onerror = null; e.target.src = dummyProfileImg; }}
                      height={40}
                      width={40}
                      className='me-2 rounded-circle'
                      alt=''
                    />
                  }

                  {userDetail?.first_name} {userDetail?.last_name}

                </a>

                <ul
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuLink'
                  onClick={handleDropdownToggle}
                >
                  <Link
                    to={userDetail?.role_as == 'supermechanic' ? PATHS.shopprofile : PATHS.profile}
                    className='decorNone'
                  >
                    <li>
                      <p className='dropdown-item'>Profile</p>
                    </li>
                  </Link>
                  <hr className='m-1' />
                  {userDetail?.role_as == 'trucker' &&
                    <>
                      <Link
                        to={PATHS.myWallet}
                        className='decorNone'
                      >
                        <li>
                          <p className='dropdown-item'>My Wallet</p>
                        </li>
                      </Link>
                      <hr className='m-1' />
                      <Link
                        to={PATHS.transactions}
                        className='decorNone'
                      >
                        <li>
                          <p className='dropdown-item'>Transactions</p>
                        </li>
                      </Link>
                      <hr className='m-1' />
                    </>
                  }
                  {userDetail?.role_as == 'supermechanic' &&
                    <>
                      <Link
                        to={PATHS.shopWallet}
                        className='decorNone'
                      >
                        <li>
                          <p className='dropdown-item'>My Wallet</p>
                        </li>
                      </Link>
                      <hr className='m-1' />
                      <Link
                        to={PATHS.transactions}
                        className='decorNone'
                      >
                        <li>
                          <p className='dropdown-item'>Transactions</p>
                        </li>
                      </Link>
                      <hr className='m-1' />
                    </>
                  }
                  <Link
                    to={PATHS.history}
                    className='decorNone'
                  >
                    <li>
                      <p className='dropdown-item'>History</p>
                    </li>
                  </Link>
                  <hr className='m-1' />
                  <li
                    className='cursor'
                    onClick={() => logoutFunc()}
                  >
                    <p
                      className='dropdown-item'
                      href='#'
                    >
                      Logout
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
