const PATHS = {
    signin: '/signin',
    signup: '/signup',
    shopregister: '/shopregister',
    forgotpassword: '/forgotpassword',
    resetpassword: '/resetpassword',
    truckerhome: '/truckerhome',
    terms: '/terms',
    privacypolicy: '/privacypolicy',
    faqs: '/faqs',
    contactus: '/contactus',
    warrantyclaim: '/warrantyclaim',
    warrantysale: '/warrantysale',
    mechanicdirectories: '/mechanicdirectories',
    vehicletype: '/vehicletype',
    vehicletrouble: '/vehicletrouble',
    profile: '/profile',
    shopprofile: '/shopprofile',
    shopprofileview: '/shopprofileview',
    requestdetails: '/requestdetails',
    notfoundpage: '/notfoundpage',
    videochatroom: '/videochatroom/:roomId',
    videochatrequestroom: '/videochatrequestroom',
    livechatbox: '/livechatbox',
    mechanics: '/mechanics',
    shophome: '/shophome',
    mechanichome: '/mechanichome',
    servicedetails: '/servicedetails',
    mapdirections: '/mapdirections',
    history: '/history',
    truckerRequests: '/truckerRequests',
    waitForResponse: '/waitForResponse',
    waitForRoadsideResponse: '/waitPlease',
    myWallet: '/myWallet',
    transactions: '/transactions',
    shopWallet: '/shopWallet',
    stripePayment: '/stripePayment',
    stripeAddBalance: '/stripeAddBalance',
}

export default PATHS;