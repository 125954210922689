export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGES;

export const API_END_POINTS = {
  /////////////////////////////<===MUTATIONS===>//////////////////////////////
  login: BASE_URL + "login",
  logout: BASE_URL + "logout",
  register: BASE_URL + "register",
  forgotPassword: BASE_URL + "forget-password",
  createShop: BASE_URL + "create-shop",
  profilUpdate: BASE_URL + "edit-profile",
  warrantyClaim: BASE_URL + "insurance-claim",
  warrantySale: BASE_URL + "shop/insurance-sale",
  addMechanic: BASE_URL + "shop/mechanic/create",
  editMechanic: BASE_URL + "shop/mechanic/update",
  deleteMechanic: BASE_URL + "shop/mechanic/delete",
  shopRating: BASE_URL + "shop/rating",
  truckerRequestCreate: BASE_URL + "trucker/request/create",
  truckerRequestAccept: BASE_URL + "shop/request/accept",
  stripePayment: BASE_URL + "stripe",
  switchChatRequest: BASE_URL + "trucker/request/switch",
  enterVideoCallRequest: BASE_URL + "trucker/request/enter",
  endChatRequest: BASE_URL + "shop/request/end",
  mechanicRequest: BASE_URL + "shop/request/assign",
  onMyWayRequest: BASE_URL + "shop/mechanic/start",
  completeRequest: BASE_URL + "shop/request/complete",
  retryRequest: BASE_URL + "shop/request/retry",
  chatNotify: BASE_URL + "send",
  addMoreServices: BASE_URL + "shop/request/add-service",
  updateShopProfile: BASE_URL + "shop/update",
  deactivateShop: BASE_URL + "shop/delete",
  activateShop: BASE_URL + "active-shop",
  shopPriceQuotation: BASE_URL + "shop/request/add-quote",
  contactUs: BASE_URL + "contact-us",
  requestAcceptByTrucker: BASE_URL + "trucker/request/accept",
  ratingFromMechanic: BASE_URL + "shop/mechanic/rating",
  cancelRequestByTrucker: BASE_URL + "done",
  creditWallet: BASE_URL + "trucker/credit-wallet",
  withdrawShopAmount: BASE_URL + "shop/withdrawal",
  stripePaymentIntent: BASE_URL + "stripe-payment-intent",
  /////////////////////////////<===QUERIES===>////////////////////////////////
  viewTruckerRequest: BASE_URL + "trucker/request/view",
  getAllMechanics: BASE_URL + "shop/mechanics",
  getActiveRequests: BASE_URL + "trucker/request/active",
  getActiveLiveChatRequests: BASE_URL + "shop/request/active-livechats",
  getTruckerDetails: BASE_URL + "trucker/get",
  viewShopProfile: BASE_URL + "shop/view-shop",
  refundPayment: BASE_URL + "trucker/request/cancel",
  shopProfile: BASE_URL + "shop-profile",
  cmsPages: BASE_URL + "shop/cms",
  faqs: BASE_URL + "shop/faqs",
  mechanicDirectories: BASE_URL + "trucker/nearby-shop",
  history: BASE_URL + "history",
  pendingRequests: BASE_URL + "shop/pending-requests",
  pendingReqForTrucker: BASE_URL + "trucker/request/pending",
  walletBalance: BASE_URL + "trucker/wallet",
  transactionHistory: BASE_URL + "trucker/transactions",
  shopTotalEarnings: BASE_URL + "shop/total-earning",
};