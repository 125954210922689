import "./App.css";
import Router from "./routes/routes";
import Navbar from "./components/navbar/Navbar";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  database,
  firebaseAuth,
  messaging,
} from "./components/firebaseConfig/firebaseConfig";
import {
  getAllRequest,
  getTruckerRequest,
  mechanicDetails,
  shopAndTruckerData,
  shopDetailsOnRequest,
  truckerDetails,
  truckerRequestAcceptData,
  clearAllRequest,
  removefromAllRequest
} from "../src/redux/requestSlice";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NavbarWithoutAuth from "./components/navbar/NavbarWithoutAuth";
import { useLocation } from "react-router-dom";

function App() {
  const auth = useSelector((data) => data?.auth);
  const dispatch = useDispatch();
  const dbRef = database.ref(`request`);
  const location = useLocation();

  console.log("location", location?.pathname);
  // const databaseRef = database.ref(dbRef);
  // const [isPageVisible, setIsPageVisible] = useState(true);

  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker
  //     .register("/firebase-messaging-sw.js", { scope: "/" })
  //     .then((registration) => {
  //       // console.log('Firebase Service Worker Registered:', registration);
  //     })
  //     .catch((error) => {
  //       console.log("Firebase Service Worker Registration Failed:", error);
  //     });
  // }

  // const handleVisibilityChange = () => {
  //   setIsPageVisible(!document.hidden);
  // };

  // console.log(isPageVisible, "yes");

  // useEffect(() => {
  //   // Add event listener for visibility change
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  useEffect(() => {
    const messageListener = (payload) => {
      toast.success(payload?.data?.message);
    };
    messaging.onMessage(messageListener);
    return () => {
      messaging.onMessage(messageListener);
    };
  }, [auth?.isLoggedIn, messaging]);

  // const getDataFromFirebase = async () => {
  // };
  // useEffect(() => {
  //   getDataFromFirebase();
  // }, []);
  //Run on realTime database Change

  useEffect(() => {
    database
      .ref(dbRef)
      .orderByKey()
      //.limitToLast(1)
      .on("value", (snapshot) => {
        //clear all requests data on first time call
        // dispatch(clearAllRequest())
        console.log("IsRun")
        snapshot?.forEach((childSnapshot) => {
          const allRequests = childSnapshot.val();
          console.log("allRequests", allRequests, auth?.fcmtoken);
          if (allRequests?.fcms?.includes(auth?.fcmtoken)) {
            console.log("hain");
            const backgroundData = allRequests;
            const notificationDataShop = backgroundData?.shop;
            const notificationDataShopAndTrucker = backgroundData?.data;
            const notificationDataMechanic = backgroundData?.mechanic;
            const notificationDataTrucker = backgroundData?.trucker;
            if (auth?.userDetail?.role_as == "supermechanic") {
              if (
                notificationDataShop != undefined ||
                notificationDataShopAndTrucker != undefined
              ) {
                dispatch(shopAndTruckerData(notificationDataShopAndTrucker));
                dispatch(getAllRequest(notificationDataShop));
                dispatch(shopDetailsOnRequest(notificationDataShop));
                dispatch(truckerRequestAcceptData(notificationDataShop));
                console.log(
                  "notificationDataShopBackground==>",
                  notificationDataShop
                );
              }
            } else if (auth?.userDetail?.role_as == "mechanic") {
              if (notificationDataMechanic != undefined) {
                dispatch(getAllRequest(notificationDataMechanic));
                dispatch(mechanicDetails(notificationDataMechanic));
                console.log(
                  "notificationDataMechanic==>",
                  notificationDataMechanic
                );
              }
            } else if (auth?.userDetail?.role_as == "trucker") {
              if (
                notificationDataTrucker != undefined ||
                notificationDataShopAndTrucker != undefined
              ) {
                dispatch(shopAndTruckerData(notificationDataShopAndTrucker));
                dispatch(shopDetailsOnRequest(notificationDataTrucker));
                dispatch(truckerDetails(notificationDataTrucker));
                dispatch(getTruckerRequest(notificationDataTrucker));
                console.log(
                  "notificationDataTrucker==>",
                  notificationDataTrucker
                );
              }
            }
            // toast.success(allRequests?.message);
          }
          // if (allRequests?.fcms?.includes(auth?.fcmtoken)) {
          //   // if (auth?.userDetail?.role_as == "supermechanic" && allRequests?.action == "create") {
          //   //   // dispatch(getAllRequest(allRequests?.shop));
          //   // }
          //   // if (auth?.userDetail?.role_as == "trucker" && allRequests?.action == "accept") {
          //   //   // dispatch(getAllRequest(allRequests?.trucker));
          //   // }
          // }
        });
      });

    var nodeRef = database.ref('request');

    // Listen for child_removed event
    nodeRef.on('child_removed', function (snapshot) {
      var deletedChildData = snapshot.val();
      console.log('check deleted');
      console.log(deletedChildData);
      if (auth?.userDetail?.role_as == "supermechanic") {
        const notificationDataShop = deletedChildData?.shop;
        if (notificationDataShop != undefined) {
          dispatch(removefromAllRequest(notificationDataShop));
        }

      }
      if (auth?.userDetail?.role_as == "mechanic") {
        const notificationDataMechanic = deletedChildData?.mechanic;
        if (notificationDataMechanic != undefined) {
          dispatch(removefromAllRequest(notificationDataMechanic));
        }
      }
      //console.log('Child removed:', deletedChildData);
      // Perform your custom action here
      // For example, update the UI or trigger some other function
    });
  }, [auth.userDetail]);

  // Run when data change in realtime database
  // databaseRef
  //   ?.orderByChild("timestamp")
  //   .limitToLast(1)
  //   .on("value", (snapshot) => {
  //     snapshot?.forEach((childSnapshot) => {
  //       //Check for web app in background
  //       console.log("isPageVisible in back-->", isPageVisible);
  //       if (!isPageVisible) {
  //         const backgroundData = childSnapshot?.val();
  //         const notificationDataShop = backgroundData?.shop;
  //         const notificationDataShopAndTrucker = backgroundData?.data;
  //         const notificationDataMechanic = backgroundData?.mechanic;
  //         const notificationDataTrucker = backgroundData?.trucker;
  //         if (auth?.userDetail?.role_as == "supermechanic") {
  //           if (
  //             notificationDataShop != undefined ||
  //             notificationDataShopAndTrucker != undefined
  //           ) {
  //             dispatch(shopAndTruckerData(notificationDataShopAndTrucker));
  //             dispatch(getAllRequest(notificationDataShop));
  //             dispatch(shopDetailsOnRequest(notificationDataShop));
  //             dispatch(truckerRequestAcceptData(notificationDataShop));
  //             console.log(
  //               "notificationDataShopBackground==>",
  //               notificationDataShop
  //             );
  //           }
  //         } else if (auth?.userDetail?.role_as == "mechanic") {
  //           if (notificationDataMechanic != undefined) {
  //             dispatch(getAllRequest(notificationDataMechanic));
  //             dispatch(mechanicDetails(notificationDataMechanic));
  //             console.log(
  //               "notificationDataMechanic==>",
  //               notificationDataMechanic
  //             );
  //           }
  //         } else if (auth?.userDetail?.role_as == "trucker") {
  //           if (
  //             notificationDataTrucker != undefined ||
  //             notificationDataShopAndTrucker != undefined
  //           ) {
  //             dispatch(shopAndTruckerData(notificationDataShopAndTrucker));
  //             dispatch(shopDetailsOnRequest(notificationDataTrucker));
  //             dispatch(truckerDetails(notificationDataTrucker));
  //             dispatch(getTruckerRequest(notificationDataTrucker));
  //             console.log(
  //               "notificationDataTrucker==>",
  //               notificationDataTrucker
  //             );
  //           }
  //         }
  //       }
  //     });
  //   });
  // if (!isPageVisible) {
  // console.log("allRequests-->", allRequests);
  // console.log("DataBase");
  // }
  // const getDataFromFirebase = () => {
  //   database.ref(dbRef).orderByKey().limitToLast(1).once('child_added')
  //     .then((snapshot) => {
  //       const allRequests = snapshot.val();
  //       if (auth?.userDetail?.role_as == "supermechanic") {
  //         dispatch(getAllRequest(allRequests?.shop));
  //       }
  //     })
  // };
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden) {
  //       getDataFromFirebase();
  //     }
  //   };
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  const signInAnonymously = async () => {
    firebaseAuth.signInAnonymously()
      .then(() => {
        console.log('User signed in anonymously');
      })
      .catch(error => {
        if (error.code === 'auth/operation-not-allowed') {
          console.log('Enable anonymous in your firebase console.');
        }
        console.error(error);
      });
  };

  useEffect(() => {
    signInAnonymously();
  }, []);

  return (
    <div className="containerDiv p-0">
      {auth?.isLoggedIn && auth?.userDetail &&
        <Navbar />
      }
      {/* {auth?.isLoggedIn && auth?.userDetail ?
        <Navbar />
        :
        <>
          <NavbarWithoutAuth />
        </>
      } */}
      <Router />
    </div>
  );
}

export default App;