import React from 'react';
import { loaderAsset } from '../../assets/imagesPath';

const Loader = () => {
    return (
        <div className='vh-100 d-flex align-content-between justify-content-center text-center'>
            <img src={loaderAsset} alt="loader" width={100} height={100} />
        </div>
    );
};

export default Loader;
