import React, { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import PATHS from "./Paths";
// import NavbarLayout from "../layouts/NavbarLayout";
import { useSelector } from "react-redux";
import Loader from "../components/loader/Loader";

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );
};
export default function Router() {

    const auth = useSelector((data) => data.auth);

    return useRoutes(
        auth.isLoggedIn && auth.userDetail ?
            [
                { path: "/", element: <Navigate to={auth?.userDetail?.role_as == "mechanic" ? PATHS.mechanichome : auth?.userDetail?.role_as == "supermechanic" ? PATHS.shophome : PATHS.truckerhome} /> },
                // { path: "*", element: <NotFoundPage /> },
                { path: PATHS.truckerhome, element: <TruckerHome /> },
                { path: PATHS.warrantyclaim, element: <WarrantyClaim /> },
                { path: PATHS.warrantysale, element: <WarrantySale /> },
                { path: PATHS.mechanicdirectories, element: <MechanicDirectories /> },
                { path: PATHS.vehicletype, element: <VehicleType /> },
                { path: PATHS.vehicletrouble, element: <VehicleTrouble /> },
                { path: PATHS.faqs, element: <Faqs /> },
                { path: PATHS.terms, element: <Terms /> },
                { path: PATHS.privacypolicy, element: <Privacy /> },
                { path: PATHS.contactus, element: <Contactus /> },
                { path: PATHS.profile, element: <Profile /> },
                { path: PATHS.shopprofile, element: <ShopProfile /> },
                { path: PATHS.shopprofileview, element: <ShopProfileView /> },
                { path: PATHS.requestdetails, element: <RequestDetails /> },
                { path: PATHS.videochatroom, element: <VideoChatRoom /> },
                { path: PATHS.videochatrequestroom, element: <VideoChatRequestRoom /> },
                { path: PATHS.livechatbox, element: <LiveChatBox /> },
                { path: PATHS.mechanics, element: <Mechanics /> },
                { path: PATHS.shophome, element: <ShopHome /> },
                { path: PATHS.mechanichome, element: <MechanicHome /> },
                { path: PATHS.servicedetails, element: <ServiceDetails /> },
                { path: PATHS.mapdirections, element: <MapDirections /> },
                { path: PATHS.history, element: <History /> },
                { path: PATHS.truckerRequests, element: <TruckerRequests /> },
                { path: PATHS.waitForResponse, element: <WaitScreen /> },
                { path: PATHS.waitForRoadsideResponse, element: <WaitPlease /> },
                { path: PATHS.myWallet, element: <MyWallet /> },
                { path: PATHS.transactions, element: <Transactions /> },
                { path: PATHS.shopWallet, element: <ShopWallet /> },
                { path: PATHS.stripePayment, element: <StripePayment /> },
                { path: PATHS.stripeAddBalance, element: <StripeAddBalance /> },
            ] : [
                { path: "/", element: <Navigate to={PATHS.signin} /> },
                { path: PATHS.signin, element: <SignIn /> },
                { path: PATHS.shopregister, element: <ShopRegister /> },
                { path: PATHS.forgotpassword, element: <ForgotPassword /> },
                { path: PATHS.resetpassword, element: <ResetPassword /> },
                { path: PATHS.signup, element: <SignUp /> },
                // { path: "*", element: <NotFoundPage /> },
                { path: PATHS.faqs, element: <Faqs /> },
                { path: PATHS.terms, element: <Terms /> },
                { path: PATHS.privacypolicy, element: <Privacy /> },
                { path: PATHS.contactus, element: <Contactus /> },
            ]
    );
}

const SignIn = Loadable(lazy(() => import("../pages/auth/Signin")));
const SignUp = Loadable(lazy(() => import("../pages/auth/Signup")));
const ShopRegister = Loadable(lazy(() => import("../pages/auth/ShopRegister")));
const ForgotPassword = Loadable(lazy(() => import("../pages/auth/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("../pages/auth/ResetPassword")));
const TruckerHome = Loadable(lazy(() => import("../pages/home/TruckerHome")));
const WarrantyClaim = Loadable(lazy(() => import("../pages/warranty/WarrantyClaim")));
const WarrantySale = Loadable(lazy(() => import("../pages/warranty/WarrantySale")));
const MechanicDirectories = Loadable(lazy(() => import("../pages/mechanicDirectories/MechanicDirectories")));
const VehicleType = Loadable(lazy(() => import("../pages/vehicleType/VehicleType")));
const VehicleTrouble = Loadable(lazy(() => import("../pages/vehicleTrouble/VehicleTrouble")));
const Contactus = Loadable(lazy(() => import("../pages/contactusPage/Contactus")));
const Faqs = Loadable(lazy(() => import("../pages/cmsPages/Faqs")));
const Terms = Loadable(lazy(() => import("../pages/cmsPages/Terms")));
const Privacy = Loadable(lazy(() => import("../pages/cmsPages/PrivacyPolicy")));
const Profile = Loadable(lazy(() => import("../pages/profile/Profile")));
const ShopProfile = Loadable(lazy(() => import("../pages/profile/ShopProfile")));
const ShopProfileView = Loadable(lazy(() => import("../pages/profile/ShopProfileView")));
const RequestDetails = Loadable(lazy(() => import("../pages/requestDetails/RequestDetails")));
const NotFoundPage = Loadable(lazy(() => import("../components/404NotFound/NotFoundPage")));
const VideoChatRoom = Loadable(lazy(() => import("../pages/videoChatRoom/VideoChatRoom")));
const VideoChatRequestRoom = Loadable(lazy(() => import("../pages/videoChatRoom/VideoChatRequest")));
const LiveChatBox = Loadable(lazy(() => import("../pages/liveChatBox/Chatbox")));
const Mechanics = Loadable(lazy(() => import("../pages/allMechanics/Mechanics")));
const ShopHome = Loadable(lazy(() => import("../pages/home/ShopHome")));
const MechanicHome = Loadable(lazy(() => import("../pages/home/MechanicHome")));
const ServiceDetails = Loadable(lazy(() => import("../pages/requestDetails/ServiceDetail")));
const MapDirections = Loadable(lazy(() => import("../components/googleMap/GoogleMap")));
const History = Loadable(lazy(() => import("../pages/history/History")));
const TruckerRequests = Loadable(lazy(() => import("../pages/truckerRequests/TruckerRequests")));
const WaitScreen = Loadable(lazy(() => import("../pages/waitingScreen/WaitingScreen")));
const WaitPlease = Loadable(lazy(() => import("../pages/waitingScreen/WaitingScreenRoadside")));
const MyWallet = Loadable(lazy(() => import("../pages/wallet/MyWallet")));
const Transactions = Loadable(lazy(() => import("../pages/wallet/MyTransactions")));
const ShopWallet = Loadable(lazy(() => import("../pages/wallet/ShopWallet")));
const StripePayment = Loadable(lazy(() => import("../components/stripeConfig/StripePaymentForm")));
const StripeAddBalance = Loadable(lazy(() => import("../components/stripeConfig/StripeAddBalance")));