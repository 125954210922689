import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// const persistConfig = {
    //     key: "requestSlice",
    //     version: 1,
    //     storage,
    //     blacklist: ['requestSlice'],
// };

const initialState = {
    allRequest: [],
    truckerRequest: [],
    activeRequestChats: [],
    requestDetail: null,
    shopDetails: null,
    chatTypeDetails: null,
    mechanicDetails: null,
    truckerDetails: null,
    activeRequest: null,
    truckerRequestAcceptData: null,
    currentLocationData: null,
    shopAndTruckerData: null,
    requestSent: false,
    roadsideWaiting: null,
};

export const requestSlice = createSlice({
    name: "request",
    initialState,
    reducers: {
        getAllRequest: (state, data) => {
            if (data?.payload !== null) {
                const newData = data;
                const isDuplicate = state.allRequest.some(item => item?.payload?.user_request_id == newData?.payload?.user_request_id);

                if (!isDuplicate) {
                    state.allRequest = [newData, ...state.allRequest];
                }
            } else {
                state.allRequest = [];
            }
        },

        clearAllRequest: (state, data) => {
            state.allRequest = [];
        },

        removefromAllRequest: (state, data) => {
            state.allRequest = state.allRequest.filter(item => JSON.stringify(item?.payload) !== JSON.stringify(data?.payload));
            console.log('yaaar yaaaaa')
            console.log(state.allRequest);
        },

        getTruckerRequest: (state, data) => {
            if (data?.payload !== null) {
                const newData = data;
                const isDuplicate = state.truckerRequest.some(item => item?.payload?.id == newData?.payload?.id);
                if (data?.payload?.request_type_id == 4) {
                    if (isDuplicate) {
                        state.truckerRequest = state.allRequest.filter(item => item?.payload.id !== data?.payload.id);
                        state.truckerRequest = [newData, ...state.truckerRequest];
                    }
                    else {
                        state.truckerRequest = [newData, ...state.truckerRequest];
                    }
                }
                else {
                    if (!isDuplicate) {
                        state.truckerRequest = [newData, ...state.truckerRequest];
                    }
                }
            } else {
                state.truckerRequest = [];
            }
        },

        deleteTruckerRequest: (state, data) => {
            if (state.truckerRequest) {
                let filterArray = state.truckerRequest.filter((item) => item?.payload?.id != data.payload);
                state.truckerRequest = filterArray;
            }
        },

        truckerRequestAcceptData: (state, data) => {
            state.truckerRequestAcceptData = data.payload;
        },

        requestSent: (state, data) => {
            state.requestSent = data.payload;
        },

        currentLocationData: (state, data) => {
            state.currentLocationData = data.payload;
        },

        shopAndTruckerData: (state, data) => {
            state.shopAndTruckerData = data.payload;
        },

        activeRequestsChats: (state, data) => {
            if (data?.payload !== null) {
                const newData = data;
                const isDuplicate = state.activeRequestChats.some(item => item?.payload?.user_request_id == newData?.payload?.user_request_id);

                if (!isDuplicate) {
                    if (state.activeRequestChats.length < 4) {
                        state.activeRequestChats = [newData, ...state.activeRequestChats];
                    }
                }
            } else {
                state.activeRequestChats = [];
            }
        },

        clickedRequest: (state, data) => {
            state.requestDetail = data.payload;
        },

        clickedActiveRequest: (state, data) => {
            state.activeRequest = data.payload;
        },

        shopDetailsOnRequest: (state, data) => {
            state.shopDetails = data.payload;
        },

        mechanicDetails: (state, data) => {
            state.mechanicDetails = data.payload;
        },

        truckerDetails: (state, data) => {
            state.truckerDetails = data.payload;
        },

        chatTypeDetail: (state, data) => {
            state.chatTypeDetails = data.payload;
        },

        roadsideWaiting: (state, data) => {
            state.roadsideWaiting = data.payload;
        },

        deleteTimeoutRequest: (state, data) => {
            if (state.allRequest) {
                let filterArray = state.allRequest.filter((item) => item?.payload?.id != data.payload);
                state.allRequest = filterArray;
            }
        },

        clearAllData: (state, data) => {
            state.allRequest = [];
            state.truckerRequest = [];
            state.activeRequestChats = [];
            state.requestDetail = null;
            state.shopDetails = null;
            state.chatTypeDetails = null;
            state.mechanicDetails = null;
            state.truckerDetails = null;
            state.activeRequest = null;
            state.truckerRequestAcceptData = null;
            state.currentLocationData = null;
            state.shopAndTruckerData = null;
            state.requestSent = false;
        },
    },
});

export const { clearAllRequest, getAllRequest, clickedRequest, deleteTimeoutRequest, shopDetailsOnRequest, chatTypeDetail, mechanicDetails, truckerDetails, clickedActiveRequest, activeRequestsChats, getTruckerRequest, deleteTruckerRequest, truckerRequestAcceptData, currentLocationData, shopAndTruckerData, requestSent, clearAllData, removefromAllRequest, roadsideWaiting } = requestSlice.actions;

export default  requestSlice.reducer
