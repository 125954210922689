import firebase from 'firebase';
import 'firebase/messaging';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAcK88tAd-5v9yFfNutZgtGV0pL3UBu2EU",
    authDomain: "truckerpro-89c5a.firebaseapp.com",
    projectId: "truckerpro-89c5a",
    storageBucket: "truckerpro-89c5a.appspot.com",
    messagingSenderId: "1094753070333",
    appId: "1:1094753070333:web:c1a7a8455a9e14a1d72294",
    measurementId: "G-8HX13W1CY9"
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();
const messaging = firebase.messaging();
const firestore = firebase.firestore();
const firebaseAuth = firebase.auth();

export { database, messaging, firestore, firebaseAuth };