import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../routes/Paths';
import { userLogout } from '../../redux/AuthSlice';
import { toast } from 'react-toastify';

const LogoutFunction = ({ error }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (error && error.status === 401) {
        toast.error(error.data.message);
        dispatch(userLogout());
        navigate(PATHS.signin);
    }

    return (
        <>
        </>
    );
};

export default LogoutFunction;
